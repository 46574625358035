import { defineStore } from 'pinia'
import homeQuery from '@/apollo/queries/pages/exchange/home.gql'
import membershipQuery from '@/apollo/queries/pages/exchange/membership.gql'
import tradingQuery from '@/apollo/queries/pages/exchange/trading.gql'
import feesQuery from '@/apollo/queries/pages/exchange/fees.gql'

export const useExchangeStore = defineStore('exchange', {
  state: () => ({
    pages: {
      home: {},
      membership: {},
      trading: {},
      fees: {},
      compliance: {}
    }
  }),
  getters: {
    getExchangeData: (state) => {
      return state.pages.home ? state.pages.home.exchange.data.attributes : {}
    },
    getMembershipData: (state) => {
      return state.pages.membership ? state.pages.membership.exchangeMembership.data.attributes : {}
    },
    getTradingData: (state) => {
      return state.pages.trading ? state.pages.trading.exchangeTrading.data.attributes : {}
    },
    getFeesData: (state) => {
      return state.pages.fees ? state.pages.fees.exchangeFee.data.attributes : {}
    }
  },
  actions: {
    async requestExchangeData() {
      if (Object.keys(this.pages.home).length !== 0) return

      const { data } = await useAsyncQuery(homeQuery) // eslint-disable-line no-undef

      if (data) this.pages.home = data
    },
    async requestMembershipData() {
      if (Object.keys(this.pages.membership).length !== 0) return

      const { data } = await useAsyncQuery(membershipQuery) // eslint-disable-line no-undef

      if (data) this.pages.membership = data
    },
    async requestTradingData() {
      if (Object.keys(this.pages.trading).length !== 0) return

      const { data } = await useAsyncQuery(tradingQuery) // eslint-disable-line no-undef

      if (data) this.pages.trading = data
    },
    async requestFeesData() {
      if (Object.keys(this.pages.fees).length !== 0) return

      const { data } = await useAsyncQuery(feesQuery) // eslint-disable-line no-undef

      if (data) this.pages.fees = data
    }
  }
})
